import React from 'react';
import Select from 'react-select';

export function DashboardSelect(
    {
        marginLeft = '20px',
        marginBottom = 0,
        marginTop = 0,
        selectedOption,
        allOptions,
        handleOptionChange,
        personalNumber,
        year = null,
        allowEmpty = false
    }: any): JSX.Element {
    const colourStyles = {
        container: (styles: any) => ({...styles, minWidth: '230px', marginBottom, marginLeft, marginTop}),
        menuPortal: (base: any) => ({...base, zIndex: 9999}),
        option: (styles: any, {isFocused, isSelected}: any) => {
            return {
                ...styles,
                backgroundColor: isFocused || isSelected ? "#FA350F" : "null",
                color: isSelected ? "#FFFFFF" : "null"
            };
        }
    };

    let filteredParticipationState = allOptions;
    if (!allowEmpty) {
        filteredParticipationState = allOptions.filter((state: any) => state.value !== '');
    }

    return (<>
            {(selectedOption !== null) && (selectedOption !== undefined) && (filteredParticipationState.length > 0) &&
            <>
                <Select
                    placeholder={"Auswählen"}
                    styles={colourStyles}
                    defaultValue={filteredParticipationState.filter((state: any) => state.value === selectedOption)[0]}
                    onChange={(value) => handleOptionChange(value, personalNumber, year)}
                    options={filteredParticipationState}
                    className={"__select"}
                    classNamePrefix={"__select-inner"}
                    menuPortalTarget={document.body}
                />
            </>
            }
        </>
    );
}
