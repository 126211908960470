import React, { useEffect, useRef, useState } from "react";
import { postRegistrationForm } from "../services/ApiService";
import { Link } from "react-router-dom";
import Select from 'react-select';
import Modal from "react-modal";


export default function Index(): JSX.Element {
    // @ts-ignore
    const [state, setState] = useState({
        personalNumber: '',
        fax: '',
        firstName: '',
        lastName: '',
        email: '',
        birthdateDay: null,
        birthdateMonth: null,
        birthdateYear: null,
        streetAndHouseNo: '',
        zipAndPlace: '',
        shippingMethod: '',
        hash: '',
        error: {}
    });

    const [birthdayOptions, setBirthdayOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [successModalIsOpen, showRegistrationSuccessModal] = useState(false);


    const handleParam = (e: React.FormEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target as HTMLTextAreaElement;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelect = (value: any, event: any) => {
        const name = event.name;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        setRegistrationValues();
    }, []);

    function setRegistrationValues() {
        let birtdayValues: any = [];
        for (let i: number = 1; i <= 31; i++) {
            birtdayValues.push({
                value: i, label: i
            });
        }
        setBirthdayOptions(birtdayValues);

        let monthValues: any = [];
        for (let i: number = 0; i <= 11; i++) {
            monthValues.push({
                value: i + 1, label: new Date(2000, i).toLocaleString('de-DE', {month: 'long'})
            });
        }
        setMonthOptions(monthValues);

        const currentYear: number = new Date().getFullYear();
        let yearValues: any = [];
        for (let i: number = currentYear; i >= currentYear - 100; i--) {
            yearValues.push({
                value: i, label: i
            });
        }
        setYearOptions(yearValues);
    }

    const setError = (error: any) => {
        setState(prevState => ({
            ...prevState,
            error: error
        }));
    };


    const selectBirthdayDayStyles = {
        container: (provided: any) => ({...provided, color: 'black', flexGrow: 4, zIndex: 2}),
        option: (styles: any, {isFocused, isSelected}: any) => {
            return {
                ...styles,
                backgroundColor: isFocused || isSelected ? "#FA350F" : "null",
                color: isSelected ? "#FFFFFF" : "null"
            };
        }
    };
    const selectBirthdayMonthStyles = {
        container: (provided: any) => ({...provided, color: 'black', flexGrow: 4, marginLeft: '5px', zIndex: 2}),
        option: (styles: any, {isFocused, isSelected}: any) => {
            return {
                ...styles,
                backgroundColor: isFocused || isSelected ? "#FA350F" : "null",
                color: isSelected ? "#FFFFFF" : "null"
            };
        }
    };
    const selectBirthdayYearStyles = {
        container: (provided: any) => ({...provided, color: 'black', flexGrow: 6, marginLeft: '5px', zIndex: 2}),
        option: (styles: any, {isFocused, isSelected}: any) => {
            return {
                ...styles,
                backgroundColor: isFocused || isSelected ? "#FA350F" : "null",
                color: isSelected ? "#FFFFFF" : "null"
            };
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setError({});

        let birthdayValue: string = '';
        if (state.birthdateDay && state.birthdateMonth && state.birthdateYear) {
            // @ts-ignore
            birthdayValue = `${state.birthdateDay.value}.${state.birthdateMonth.value}.${state.birthdateYear.value}`;
        }

        postRegistrationForm(
            state.personalNumber,
            state.firstName,
            state.lastName,
            state.email,
            birthdayValue,
            state.fax,
            state.streetAndHouseNo,
            state.zipAndPlace,
            state.shippingMethod,
            state.hash)
            .then(() => {
                showRegistrationSuccessModal(true);
            })
            .catch((error) => {
                if (typeof error !== 'object') {
                    error = {generic: error};
                }
                setError(error);
            });
    };

    function toggleRegistrationSuccessModal() {
        showRegistrationSuccessModal(!successModalIsOpen);
    }

    function handleSuccessModalClose() {
        setState(prevState => ({
            ...prevState,
            personalNumber: '',
            firstName: '',
            lastName: '',
            email: '',
            birthdateDay: null,
            birthdateMonth: null,
            birthdateYear: null,
            streetAndHouseNo: '',
            zipAndPlace: '',
            shippingMethod: '',
        }));
    }

    const scrollToRef = useRef(null);
    const executeScroll = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        scrollToRef.current.scrollIntoView({behavior: 'smooth'})
    };

    return (
        <div className={"container"}>
            <div className={"__content _hero"}>
                <div className={"__hero-wrapper"}>
                    <div className={"__image"}>
                        <img src="/images/matthaei-oneshare-10720118282@0,5x_800px.jpg" alt=""/>
                    </div>
                    <div className={"__text"}>
                        <div className={"__header"}>
                            <h1 className={"__headline _large"}>Dein Anteil am Erfolg</h1>
                        </div>
                        <div className={"bodytext"}>
                            Die Matthäi Mitarbeiterbeteiligung macht den Erfolg der Gruppe zur persönlichen Chance.
                        </div>
                        <Link to={"/"}>
                            <button className={"btn btn-primary _icon"} onClick={executeScroll}>
                                <span>zum Angebot</span>
                                <div className={"__icon"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M9 5l7 7-7 7" stroke="#ffffff"/>
                                    </svg>
                                </div>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={"__content"}>
                <div className={"__intro"}>
                    <div className={"__header"}>
                        <h2 className={"__headline"}>Mitarbeiterbeteiligung</h2>
                    </div>
                    <div className={"__teaser"}>
                        <p>Gemeinsam erfolgreich zu sein, ist ein gutes Gefühl. Das erleben wir täglich auf unseren
                            Baustellen und in unseren Büros. Die Mitarbeiterbeteiligung gibt seit einigen Jahren
                            zusätzlich die Möglichkeit, am wirtschaftlichen Erfolg der Gruppe teilzuhaben – zu
                            Konditionen, die sich lohnen.
                        </p>
                    </div>
                </div>
                <div className={"__quick-info _content-padding-lg"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                            <div className={"__quick-info-wrapper"}>
                                <div className={"__icon"}>
                                    <img src="../images/logos/oneshare_cash.svg" alt=""/>
                                </div>
                                <div className={"__headline"}>
                                    Bis zu 360 € Zulage
                                </div>
                                <div className={"bodytext"}>
                                    Zusätzlich zu Ihrer geplanten Einlage erhalten Sie von Matthäi eine Zulage von bis
                                    zu 360 Euro.
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 col-md-4"}>
                            <div className={"__quick-info-wrapper"}>
                                <div className={"__icon"}>
                                    <img src="../images/logos/oneshare_7percent.svg" alt=""/>
                                </div>
                                <div className={"__headline"}>
                                    Bis zu 7 % Verzinsung
                                </div>
                                <div className={"bodytext"}>
                                    Über die attraktive Grundverzinsung hinaus sind im Erfolgsfall bis zu 7 Prozent
                                    Rendite möglich.
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 col-md-4"}>
                            <div className={"__quick-info-wrapper"}>
                                <div className={"__icon"}>
                                    <img src="../images/logos/oneshare_handshake.svg" alt=""/>
                                </div>
                                <div className={"__headline"}>
                                    Einfach & transparent
                                </div>
                                <div className={"bodytext"}>
                                    Dieses sehr transparente Anlagemodell überzeugt durch einfache und übersichtliche
                                    Kontoführung.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"__register-wrapper"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6 col-lg-7"}>
                            <div className={"__info-wrapper"}>
                                <div className={"__header"}>
                                    <h3 className={"__headline"}>
                                        Gemeinsam erfolgreich –
                                        jetzt und in Zukunft!
                                    </h3>
                                </div>
                                <div className={"__teaser"}>
                                    Der Erfolg der Matthäi-Gruppe? Das sind Sie – <br/>
                                    zusammen mit über 2.500 Kolleginnen und Kollegen an allen Standorten.
                                </div>
                                <div className={"bodytext"}>
                                    <p>Seit einigen Jahren gibt es für Sie die Möglichkeit, im Rahmen einer
                                        Mitarbeiterbeteiligung finanziell am Erfolg der Matthäi-Gruppe teilzuhaben.
                                        Seitdem haben viele Kolleginnen und Kollegen diese Gelegenheit ergriffen.
                                    </p>

                                    <p>Die Änderungen aus dem Vorjahr – Zahlung der Zuschläge vom Arbeitgeber im
                                        September des Folgejahres und die Beschränkung der Bürgschaftssumme auf EUR 360
                                        – bleiben bestehen.
                                    </p>

                                    <p><b>Bitte beachten Sie auch diese weitere Änderung:</b></p>

                                    <p>Für das Beteiligungsangebot 2021 erhalten Sie weiterhin einen Zuschuss vom
                                        Arbeitgeber von bis zu maximal EUR 360 (steuer- und sozialabgabenfrei).
                                    </p>

                                    <p>Darüber hinaus können Sie nun bis zu einem Betrag von EUR 1.080 (steuer- aber
                                        nicht sozialabgabenfrei) im Zuge der Entgeltumwandlung über Ihre
                                        Lohn-/Gehaltsabrechnung in die MMBG einzahlen.
                                    </p>

                                    <p>Alle wichtigen Informationen dazu finden Sie im Beteiligungsangebot
                                        2021.
                                    </p>
                                </div>
                            </div>
                            <div className={"__contact-partner"}>
                                <div className={"__header"}>
                                    <h3 className={"__headline"}>
                                        Ansprechpartner
                                    </h3>
                                </div>
                                <div className={"__image-text-wrapper"}>
                                    <div className={"__image"}>
                                        <img src="images/oneshare-contact-person-brunken.jpg" alt=""/>
                                    </div>
                                    <div className={"__text"}>
                                        <div>Nicolas Ilari Brunken<br/>
                                            Tel.: 04231 766-244<br/>
                                            <a href={"mailto:mitarbeiterbeteiligung@matthaei.de"} target="_blank"
                                               rel="noreferrer">mitarbeiterbeteiligung@matthaei.de
                                            </a>
                                            <br/>
                                            <dl className={"row __opening-hours"}>
                                                <dt className={"col-3"}>Mo - Do</dt>
                                                <dd className={"col-9"}>8.00 - 17.00</dd>
                                                <dt className={"col-3"}>Fr</dt>
                                                <dd className={"col-9"}>8.00 - 13.30</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6 col-lg-5"}>
                            <div ref={scrollToRef} className={"__register"}>
                                <div className={"__header"}>
                                    <h4 className={"__headline"}>Jetzt mitmachen!</h4>
                                </div>
                                {/*todo: move to component*/}
                                <form id={"register-form"} className={"form register-form"}
                                      onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"personalNumber"}>
                                            Personalnummer *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"personalNumber"}
                                               value={state.personalNumber}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('personalNumber' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene Personalnummer ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"firstName"}>
                                            Vorname *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"firstName"}
                                               value={state.firstName}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('firstName' in state.error ? 'block' : 'none')}}>
                                            Der eingegebene Vorname ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"lastName"}>
                                            Nachname *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"lastName"}
                                               value={state.lastName}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('lastName' in state.error ? 'block' : 'none')}}>
                                            Der eingegebene Nachname ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"email"}>
                                            E-Mail-Adresse *
                                        </label>
                                        <input className={"form-control"}
                                               type="mail"
                                               name={"email"}
                                               value={state.email}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('email' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene E-Mail-Adresse ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group _birthdate"}>
                                        <fieldset>
                                            <label className={"form-label"} htmlFor={"birthdate"}>
                                                Geburtsdatum *
                                            </label>
                                            <span className={"__input-wrapper"}>
                                                <Select
                                                    placeholder={"TT"}
                                                    name={"birthdateDay"}
                                                    onChange={handleSelect}
                                                    styles={selectBirthdayDayStyles}
                                                    options={birthdayOptions}
                                                    value={state.birthdateDay}
                                                    className={"__select"}
                                                    classNamePrefix={"__select-inner"}
                                                />
                                                <Select
                                                    placeholder={"MM"}
                                                    name={"birthdateMonth"}
                                                    onChange={handleSelect}
                                                    styles={selectBirthdayMonthStyles}
                                                    options={monthOptions}
                                                    value={state.birthdateMonth}
                                                    className={"__select"}
                                                    classNamePrefix={"__select-inner"}
                                                />
                                                <Select
                                                    placeholder={"JJJJ"}
                                                    onChange={handleSelect}
                                                    name={"birthdateYear"}
                                                    styles={selectBirthdayYearStyles}
                                                    options={yearOptions}
                                                    value={state.birthdateYear}
                                                    className={"__select"}
                                                    classNamePrefix={"__select-inner"}
                                                />
                                            </span>
                                            <div className="invalid-feedback"
                                                 style={{display: ('birthdate' in state.error ? 'block' : 'none')}}>
                                                Das eingegebene Geburtsdatum ist falsch.
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"streetAndHouseNo"}>
                                            Straße, Nr. *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"streetAndHouseNo"}
                                               value={state.streetAndHouseNo}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('streetAndHouseNo' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene Anschrift ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"zipAndPlace"}>
                                            PLZ, Ort *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"zipAndPlace"}
                                               value={state.zipAndPlace}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('zipAndPlace' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene Postleitzahl ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <fieldset>
                                            <label className={"__radio-label _white"}>
                                                Ich möchte zukünftig meine Unterlagen bekommen:
                                            </label>
                                            <div className="custom-control custom-radio"
                                                 onChange={(e: any) => handleParam(e)}>
                                                <div className="radio-circle _black">
                                                    <input type="radio" className={"custom-control-input"}
                                                           name="shippingMethod"
                                                           id="shippingMethod"
                                                           value="post"
                                                           checked={state.shippingMethod === "post"}
                                                    >
                                                    </input>
                                                    <label className={"custom-control-label"} htmlFor="shippingMethod">
                                                        per Post
                                                    </label>
                                                </div>
                                                <div className="radio-circle _black">
                                                    <input type="radio" className={"custom-control-input"}
                                                           name="shippingMethod"
                                                           id="shippingMethod2"
                                                           value="email"
                                                           checked={state.shippingMethod === "email"}
                                                    >
                                                    </input>
                                                    <label className={"custom-control-label"} htmlFor="shippingMethod2">
                                                        per E-Mail
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div className="invalid-feedback"
                                             style={{display: ('shippingMethod' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene Versandmethode ist falsch.
                                        </div>

                                    </div>

                                    <input
                                        style={{
                                            opacity: 0,
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            height: '0',
                                            width: '0',
                                            zIndex: -1,
                                        }}
                                        tabIndex={-1}
                                        autoComplete="off"
                                        type="text"
                                        id="fax"
                                        name="fax"
                                        placeholder=""
                                        onChange={(e: any) => handleParam(e)}
                                    />

                                    <div className="__submit">
                                        <button id="register-submit" type="submit" className="btn btn-white _btn-bold"
                                                value="submit">Jetzt Unterlagen anfordern
                                        </button>
                                        <div className="invalid-feedback"
                                             style={{display: ('generic' in state.error ? 'block' : 'none')}}>
                                            {('generic' in state.error) &&
                                            // @ts-ignore
                                            state.error.generic
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={successModalIsOpen}
                onRequestClose={toggleRegistrationSuccessModal}
                contentLabel="My dialog"
                className="successModal"
                onAfterClose={handleSuccessModalClose}
                style={{
                    overlay: {zIndex: 999, backgroundColor: '#00000054'},
                    content: {
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'white',
                        padding: '50px 100px',
                        textAlign: 'center'
                    }
                }}
            >
                <p><img src="/images/check-circle-thin-green.svg" alt=""/></p>
                <p className={"__thx-text"}>Vielen Dank.<br/>Schön, dass Sie mitmachen!</p>
                <p>Sie erhalten <strong>in den nächsten Tagen</strong><br/> ihre Vertragsunterlagen
                    per {(state.shippingMethod === "post") ? 'Post' : "E-Mail"}.
                </p>

                <button onClick={toggleRegistrationSuccessModal} style={{
                    background: 'transparent',
                    position: "absolute" as "absolute",
                    top: 10,
                    right: 10,
                    border: 'none'
                }}>
                    <img src="/images/heroicons-outline/x.svg" style={{height: '20px'}} alt=""/>
                </button>
            </Modal>

        </div>
    );
}
