export default function DoubleRowCell({firstRow, redirectToEmployeeDetailPage}: any): JSX.Element {
    return (
        <div onClick={redirectToEmployeeDetailPage} style={{cursor: 'pointer'}}>
            <div style={{fontWeight: 400, paddingBottom: '3px', fontSize: '14px'}}>
                {firstRow !== ''
                    ? firstRow
                    : <div style={{fontWeight: 400, paddingBottom: '3px', fontSize: '14px'}}>&nbsp;</div>
                }
            </div>
            <div className={'text-muted'} style={{fontSize: '13px'}}>&nbsp;</div>
        </div>
    )
}
