import React from 'react';

export default function SquareInfoCard(
    {
        smallTitle,
        largeTitle,
        infos,
        hasMargin = false,
        backgroundColor = '#fff'
    }: any) {
    return (
        <div className={'square-info-card'}
             style={{
                 marginRight: (hasMargin ? '20px' : '0px'),
                 backgroundColor: backgroundColor
             }}>
            <div className={'small-title'}>{smallTitle}</div>
            <div className={'large-title'}>{largeTitle}</div>
            {Object.values(infos).map((item: any, index: number) => {
                return (
                    <div className={'info-wrapper'} key={index}>
                        <div className={'title'}>{item.title}</div>
                        <div className={'value'}>{item.value}</div>
                    </div>
                )
            })}
        </div>
    )
}
