import React from "react";
import { Link } from "react-router-dom";

interface ToOverviewButtonInterface {
    redirectTo?: string
}

export default function ToOverviewButton({redirectTo = '/dashboard'}: ToOverviewButtonInterface) {
    return <Link to={{pathname: redirectTo}} className="__link">
        <div className={"__icon"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"
                      stroke="#FA350F"/>
            </svg>
        </div>
        zur Übersicht
    </Link>
}
