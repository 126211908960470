import React, { useEffect } from 'react';
import Select from 'react-select';
import { alternativeFilterLabels } from "../../redux/constants";

export function ShippingMethodSelect(
    {
        selectedShipping = "post",
        handleShippingChange,
    }: any): JSX.Element {

    useEffect(() => {
        // @ts-ignore
        handleShippingChange(alternativeFilterLabels['shipping_method'][selectedShipping]["value"]);
    }, []);

    const colourStyles = {
        container: (styles: any) => ({...styles, width: '100%', minWidth: '250px'}),
        menuPortal: (base: any) => ({...base, zIndex: 9999}),
        option: (styles: any, {isFocused, isSelected}: any) => {
            return {
                ...styles,
                backgroundColor: isFocused || isSelected ? "#FA350F" : "null",
                color: isSelected ? "#FFFFFF" : "null"
            };
        }
    };

    return <Select
        styles={colourStyles}
        // @ts-ignore
        defaultValue={alternativeFilterLabels['shipping_method'][selectedShipping]}
        onChange={(shipping: any) => {
            handleShippingChange(shipping.value)
        }}
        options={Object.values(alternativeFilterLabels['shipping_method'])}
        className={"__select"}
        classNamePrefix={"__select-inner"}
        menuPortalTarget={document.body}
    />
}
