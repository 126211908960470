// States' definition
export interface AuthenticationInterface {
    isFetching: boolean
    accessToken: string | null
    refreshToken: string | null,
    loginError: string | null,
    user: any,
}

const initialState = {
    isFetching: false,
    loginError: null,
    accessToken: null,
    refreshToken: null,
    user: null,
};

const authReducer = (state: AuthenticationInterface = initialState, action: any): AuthenticationInterface => {
    switch (action.type) {
        case 'LOGIN_USER':
            return {...state, accessToken: action.accessToken, refreshToken: action.refreshToken};
        case 'LOGIN_FETCHING':
            return {...state, isFetching: action.isFetching};
        case 'USER_CREDENTIALS_SET':
            return {...state, accessToken: action.access_token, refreshToken: action.refresh_token, user: action.user};
        case 'LOGIN_ERROR':
            return {...state, loginError: action.error};

        default:
            return state
    }
};

export default authReducer;
