import React from "react";

export default function DashboardFilter({filterText, onFilter}: any): JSX.Element {
    return (
        <div className={"__custom-subheader-component"}>
            <div className={"__select-filter"}>

            </div>
            <div className={"__search-field"}>
                <input id="search"
                       className="form-control _search"
                       type="text"
                       placeholder="Suchen"
                       aria-label="Search Input"
                       value={filterText}
                       onChange={onFilter}/>
                <div className={"icon _search"}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" stroke="#6D6D6D"/>
                    </svg>
                </div>
                {/*<button type="button" className="__search-button" onClick={onClear}>X</button>*/}
            </div>
        </div>
    );
}
