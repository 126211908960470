import { useEffect } from "react";
import Select from 'react-select';
import { defaultSelectOptions } from '../../redux/constants';

export function BillingPeriodSelect(
    {
        participation_state,
        handleParticipationStateChange,
        selectOptions = defaultSelectOptions
    }: any): JSX.Element {

    useEffect(() => {
        if (participation_state === undefined) {
            handleParticipationStateChange(selectOptions[0].value);
        }
    }, []);

    const colourStyles = {
        container: (styles: any) => ({...styles, width: '100%', minWidth: '250px'}),
        menuPortal: (base: any) => ({...base, zIndex: 9999}),
        option: (styles: any, {isFocused, isSelected}: any) => {
            return {
                ...styles,
                backgroundColor: isFocused || isSelected ? "#FA350F" : "null",
                color: isSelected ? "#FFFFFF" : "null"
            };
        }
    };

    return (
        <Select
            styles={colourStyles}
            defaultValue={{
                value: participation_state,
                label: defaultSelectOptions[participation_state]['label']
            }}
            onChange={(value: any) => {
                handleParticipationStateChange(value.value)
            }}
            options={selectOptions}
            className={"__select"}
            classNamePrefix={"__select-inner"}
            menuPortalTarget={document.body}
        />
    );
}
