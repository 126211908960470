import React from "react";
import { useParams, withRouter } from 'react-router-dom';
import Header from "../components/Basic/Header";
import ParticipationDashboard from '../components/ParticipationDashboard';
import FileUpload from '../components/FileUpload';
import ToOverviewButton from "../components/Basic/ToOverviewButton";

function Participations({location}: any): JSX.Element {
    const {year = null}: any = useParams();
    let redirectTo = '/participations';
    if (year !== null && year > 0) {
        redirectTo = `/participations/${year.toString()}`;
    }

    let filter = null;
    if (location.state !== undefined && location.state.filter !== undefined) {
        filter = location.state.filter;
    }

    let clearFilter = false;
    if (location.state !== undefined && location.state.clearFilter !== undefined) {
        clearFilter = location.state.clearFilter;
    }

    return (
        <div className={"container"}>
            <Header
                title={"Beteiligung 2021"}
                NavigationComponent={<ToOverviewButton redirectTo={redirectTo}/>}
            />
            <ParticipationDashboard
                year={year}
                redirectTo={redirectTo}
                presetFilter={filter}
                clearFilter={clearFilter}
            />
            <FileUpload
                noBorderTop={true}
            />
        </div>
    );
}

export default withRouter(Participations);
