export const defaultSelectOptions: any = {
    once_salary: {
        value: 'once_salary',
        label: 'Einmalzahlung Gehalt/Lohn'
    }, once_direct: {
        value: 'once_direct',
        label: 'Einmalzahlung Direkt'
    }, monthly_salary: {
        value: 'monthly_salary',
        label: 'Monatlich Gehalt/Lohn'
    }
};

export const alternativeFilterTitle: any = {
    'shipping_method': 'Versandmethode',
    'workplace_location': 'Standort',
    'participation_state': 'Status'
};

export const alternativeFilterLabels: any = {
    'shipping_method': {
        'post': {
            label: 'Post',
            value: 'post'
        },
        'email': {
            label: 'E-Mail',
            value: 'email'
        }
    }
};
