// store/index.ts
import {createStore, applyMiddleware} from 'redux'
import appReducer from './reducer'
import thunk from 'redux-thunk'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
    key: 'app',
    storage,
};

const persistedReducer = persistReducer(persistConfig, appReducer);

let store = createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store);

export {store, persistor};
