import React, { useRef, useState } from 'react';
import { requestApiEndpoint } from '../services/ApiService';

export default function FileUpload({noBorderTop = false}: any) {
    let inputRef = useRef(null);
    const [fileUploadStatus, setFileUploadStatus] = useState('+ Daten importieren');

    const handleFileInput = (event: any) => {
        const file = event.target.files[0];
        const fileEnding = file.name.substr(file.name.length - 5);

        if (file.type !== 'text/csv' && fileEnding !== '.xlsx') {
            setFileUploadStatus('Ungültige Datei. Die ausgewählte Datei ist keine CSV oder XLSX.');
            setTimeout(() => {
                setFileUploadStatus('+ Daten importieren')
            }, 3000);
            return;
        }

        setFileUploadStatus('Datei wird hochgeladen...');
        const requestBody = new FormData();
        requestBody.append('employees', file);
        requestApiEndpoint(requestBody, '/management/employee/import')
            .then(async data => {
                setFileUploadStatus(data.message);
                setTimeout(() => {
                    setFileUploadStatus('+ Daten importieren');
                    window.location.reload();
                }, 1500);
            })
            .catch(() => {
                setFileUploadStatus('Datei konnte nicht hochgeladen werden.');
                setTimeout(() => {
                    setFileUploadStatus('+ Daten importieren')
                }, 3000);
            })
    };

    return <div className={'export-wrapper'}>
        <input
            type="file"
            id="customFile"
            onChange={handleFileInput}
            // @ts-ignore
            ref={input => inputRef = input}
            style={{display: 'none'}}
        />
        <div
            className="csvUploadArea select-area"
            style={{borderTop: (noBorderTop ? 'none' : '')}}
            // @ts-ignore
            onClick={() => inputRef.click()}
        >{fileUploadStatus}</div>
    </div>
}
