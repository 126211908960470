import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { getAnschreibenPdf, getRegistrationFormExport, requestApiEndpoint } from '../services/ApiService';
import DoubleRowCell from "../components/Form/DoubleRowCell";
import DateCell from "../components/Form/DateCell";
import { useDispatch, useSelector } from 'react-redux';
import { setNavigation, setParticipationFilter, updateStatusForUser } from "../redux/actions";
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import DashboardFilter from '../components/DashboardFilter';
import DashboardNotifyFilter from "./Basic/DashboardNotifyFilter";
import { alternativeFilterLabels, alternativeFilterTitle } from "../redux/constants";
import DashboardPagination from "./Basic/DashboardPagination";
import { DashboardSelect } from "./Basic/DashboardSelect";
import { CSVHeaders, DashboardColumn, UserDataRow, UserParticipation } from "../types";

export default function ParticipationDashboard(
    {
        year = null,
        onDashboardVisibilityChange = () => {
        },
        filterNotReplied = false,
        redirectTo = '/dashboard',
        presetFilter = null,
        clearFilter = false,
        showDatatable = true,
        swapContractWithSalary = false,
        useDashboardHeader = false,
        updateDashboardFilter = () => {
        }
    }: any): JSX.Element {

    const CsvAnmeldungMapping: CSVHeaders = {
        "Vorname": "Vorname",
        "Nachname": "Nachname",
        "Pers.-Nr.": "Pers.-Nr.",
        "E-Mailadresse": "E-Mailadresse",
        "Geburtstag": "Geburtstag",
        "Straße und Hausnummer": "Straße und Hausnummer",
        "PLZ": "PLZ",
        "Ort": "Ort",
        "Versandmethode": "Versandmethode",
        "Erstellt": "Erstellt",
    };

    const CsvParticipationMapping: CSVHeaders = {
        "name": "Name",
        "salutation": "Anrede",
        "first_name": "Vorname",
        "last_name": "Nachname",
        "personal_number": "Personalnummer",
        "anschreiben_versendet": "Anschreiben versendet",
        "street_and_house_no": "Straße und Hausnummer",
        "place": "Ort",
        "zip": "PLZ",
        "email": "E-Mailadresse",
        "salary_kind": "Lohnart",
        "participation_total": "Beteiligung",
        "participation_once_direct_total": "Einmalig Direkt",
        "participation_once_salary_total": "Einmalig Gehalt / Lohn",
        "participation_monthly": "Monatlich",
        "workplace_location": "Standort",
        "first_entry": "Ersteintritt",
        "shipping_method": "Versand",
        "contract_sent": "Vertrag versendet",
        "participation_state": "Status",
    };

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.auth.user);
    const filters = useSelector((state: any) => state.app.filter);

    const [datatable, setDatatable] = useState([]);
    const [filteredDatatable, setFilteredDatatable] = useState([]);
    const [selectedPersonalNumbers, setSelectedPersonalNumbers] = useState([]);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [updatingDatatable, setUpdatingDatable] = React.useState(false);
    const [currentlySelectedRows, setCurrentlySelectedRows] = React.useState<UserDataRow[]>([]);
    const [dashboardReplyDurationFilter, setDashboardReplyDurationFilter] = useState('1 week');

    const [initialFilter, setInitialFilter] = useState(true);
    const [filter, setFilter] = useState({
        text: '',
        workplace_location: null,
        participation_state: null,
        shipping_method: null
    } as any);
    const [filterLabels, setFilterLabels] = useState({
        workplace_location: [],
        participation_state: [] as any,
        shipping_method: []
    } as any);

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        if (!initialFilter) {
            const inputTimeout = setTimeout(() => {
                if (filter.text !== null) {
                    filterElements({useFilter: {...filter, text: filter.text}});
                }
            }, 400);

            return () => clearTimeout(inputTimeout);
        }
    }, [filter.text]);

    const rehydrateFilters = (dashboard: any, labels: any) => {
        if (clearFilter) {
            let resetFilter: any = {
                text: '',
            };
            Object.keys(labels).forEach((labels: string) => {
                resetFilter[labels] = '';
            });
            setFilter(resetFilter);
            filterElements({useFilter: resetFilter, data: dashboard, labels});
        } else {
            let rehydrateFilter = filters[redirectTo];
            if (presetFilter !== null) {
                rehydrateFilter = filter;
            }

            let resetFilter = {
                ...rehydrateFilter,
                text: rehydrateFilter.text
            };
            Object.keys(labels).forEach((filterId: string) => {
                resetFilter[filterId] = rehydrateFilter[filterId]
            });

            setFilter(resetFilter);
            filterElements({useFilter: resetFilter, data: dashboard, labels});
        }
    };

    const getData = async (dashboardYear: string | null = year, dashboardReplyFilter = dashboardReplyDurationFilter) => {
        const requestBody = new FormData();
        if (dashboardYear !== null) {
            requestBody.append('year', dashboardYear);
        }

        if (filterNotReplied) {
            requestBody.append('filter', dashboardReplyFilter);
        }

        setUpdatingDatable(true);
        return requestApiEndpoint(requestBody, '/management/share-dashboard')
            .then((response: any) => {
                const filterLabels = prepareFilterLabels(response.dashboard.filter);
                setFilterLabels(filterLabels);

                const dashboard = generateDashboard(response.dashboard.data);
                onDashboardVisibilityChange(dashboard.length > 0);
                setClearSelectedRows(!clearSelectedRows);
                setUpdatingDatable(false);
                dispatch(setNavigation(response.menu));
                rehydrateFilters(dashboard, filterLabels);
                setInitialFilter(false);

                return dashboard;
            }).catch(err => {
                console.error(err);
            });
    };

    const prepareFilterLabels = (filterLabels: any) => {
        const formattedFilterLabels: any = {};
        Object.keys(filterLabels).forEach((filterId: string) => {
            let tmpFilterTitle = 'Alle';
            if (alternativeFilterTitle.hasOwnProperty(filterId)) {
                tmpFilterTitle = alternativeFilterTitle[filterId];
            }

            const tmpFilterLabels: any = {
                '': {
                    label: tmpFilterTitle, value: ''
                }
            };

            filterLabels[filterId].forEach((filterLabel: string, index: number) => {
                const filterValue = `${filterId}_${index}`;
                let tmpFilterLabel = filterLabel;
                if (alternativeFilterLabels.hasOwnProperty(filterId)
                    && alternativeFilterLabels[filterId].hasOwnProperty(filterLabel)
                ) {
                    tmpFilterLabel = alternativeFilterLabels[filterId][filterLabel]['label'];
                }

                tmpFilterLabels[filterValue] = {
                    label: tmpFilterLabel,
                    value: filterValue
                };
            });

            formattedFilterLabels[filterId] = tmpFilterLabels;
        });

        return formattedFilterLabels;
    };

    const handleRowClicked = (row: any) => {
        history.push({
            pathname: `/mitarbeiter/${row.personal_number}${redirectTo}`,
            state: {
                year: year
            }
        });
    };

    const downloadAsCSV = (data: UserDataRow[], filename: string, headers: CSVHeaders) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(data, headers);
        if (csv) {
            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`;
            }

            link.setAttribute('href', encodeURI(csv));
            link.setAttribute('download', filename);
            link.click();
            setClearSelectedRows(!clearSelectedRows);
        }
    };

    const participationNumberFormat = (amount: number): string => {
        let formatted = '';
        if (amount > 0) {
            formatted = `${amount}`;
        }

        return formatted;
    };

    // todo: format
    const generateDashboard = (data: any) => {
        const formattedDashboard: any = [];

        data.forEach((user: any) => {
            let participation_total: number = 0.0;
            let participation_once_total: number = 0.0;
            let participation_once_direct_total: number = 0.0;
            let participation_once_salary_total: number = 0.0;
            let participation_monthly: number = 0.0;

            if (user.participation && user.participation.length > 0) {
                user.participation.forEach((participation: UserParticipation) => {
                    switch (participation.billing_period) {
                        case 'once_direct':
                            participation_total += participation.deposit;
                            participation_once_total += participation.deposit;
                            participation_once_direct_total += participation.deposit;
                            break;
                        case 'once_salary':
                            participation_total += participation.deposit;
                            participation_once_total += participation.deposit;
                            participation_once_salary_total += participation.deposit;
                            break;
                        case 'monthly_salary':
                            participation_total += 12 * participation.deposit;
                            participation_monthly += participation.deposit;
                            break;
                    }
                })
            }

            formattedDashboard.push({
                'name': `${user.last_name}, ${user.first_name}`,
                'salutation': user.salutation,
                'first_name': user.first_name,
                'last_name': user.last_name,
                'email': user.email,
                'zip': user.zip,
                'shipping_method': user.shipping_method ? user.shipping_method : "",
                'street_and_house_no': user.street_and_house_no,
                'place': user.place,
                'personal_number': user.personal_number,
                'salary_kind': user.salary_kind,
                'workplace_location': user.workplace_location,
                'participation_state': user.participation_state,
                'participation_total': participationNumberFormat(participation_total),
                'participation_once_direct_total': participationNumberFormat(participation_once_direct_total),
                'participation_once_salary_total': participationNumberFormat(participation_once_salary_total),
                'participation_once_total': participationNumberFormat(participation_once_total),
                'participation_monthly': participationNumberFormat(participation_monthly),
                'first_entry': user.first_entry,
                "anschreiben_versendet": user.anschreiben_versendet ? user.anschreiben_versendet : "",
                "contract_sent": user.contract_sent ? user.contract_sent : "",
            })
        });
        setDatatable(formattedDashboard);
        setFilteredDatatable(formattedDashboard);
        return formattedDashboard;
    };

    async function handleParticipationStateChange(status: any, personalNumber: any, year: number | null) {
        setUpdatingDatable(true);
        await dispatch(updateStatusForUser(status.label, personalNumber, year));
        const data = await getData();
        filterElements({data});
    }

    function onSelectedRowChange({selectedRows}: any) {
        const selectedPersonalNumbers: any = [];
        selectedRows.forEach((row: any) => {
            selectedPersonalNumbers.push(row.personal_number);
        });

        setCurrentlySelectedRows(selectedRows);
        setSelectedPersonalNumbers(selectedPersonalNumbers);
    }

    const billingPeriodTranslations = {
        'participation_total': 'gesamt',
        'participation_once_total': 'einmalig',
        'monthly': 'monatlich'
    };

    const createDashboardStatusSelectOptions = (row: any) => {
        // @ts-ignore
        const states = Object.values(filterLabels.participation_state);
        // @ts-ignore
        return states.filter((part_state: any) => row.participation_state === part_state.label)[0]['value']
    };
    const columns: DashboardColumn[] = [
        {
            name: 'Name',
            selector: 'name', // todo
            sortable: true,
            minWidth: '200px',
            omit: false,
            cell: (row: any) => (
                <DoubleRowCell
                    firstRow={row.name}
                    secondRow={row.personal_number}
                    redirectToEmployeeDetailPage={() => handleRowClicked(row)}
                />
            )
        },
        {
            name: 'Anrede',
            selector: 'salutation',
            sortable: false,
            omit: true,
        },
        {
            name: 'Vorname',
            selector: 'first_name',
            sortable: false,
            omit: true,
        },
        {
            name: 'Nachname',
            selector: 'last_name',
            sortable: false,
            omit: true,
        },
        {
            name: 'Personalnummer',
            selector: 'personal_number',
            sortable: false,
            omit: true,
        }, {
            name: 'Anschreiben versendet',
            selector: 'anschreiben_versendet',
            sortable: false,
            omit: true,
        }, {
            name: 'Straße und Hausnummer',
            selector: 'street_and_house_no',
            sortable: false,
            omit: true,
        }, {
            name: 'Ort',
            selector: 'place',
            sortable: false,
            omit: true,
        }, {
            name: 'PLZ',
            selector: 'zip',
            sortable: false,
            omit: true,
        }, {
            name: 'E-Mailadresse',
            selector: 'email',
            sortable: false,
            omit: true,
        },
        {
            name: 'Lohnart',
            selector: 'salary_kind',
            sortable: true,
            omit: swapContractWithSalary,
            width: '80px',
            cell: (row: any) => (
                <DoubleRowCell
                    firstRow={row.salary_kind}
                    secondRow={''}
                    redirectToEmployeeDetailPage={() => handleRowClicked(row)}
                />
            )
        },
        {
            name: 'Beteiligung',
            selector: 'participation_total',
            sortable: true,
            maxWidth: '150px',
            omit: false,
            cell: (row: any) => (
                <DoubleRowCell
                    firstRow={row.participation_total}
                    type={"currency"}
                    secondRow={((row.participation_total !== "") && billingPeriodTranslations["participation_total"])}
                    redirectToEmployeeDetailPage={() => handleRowClicked(row)}
                />
            )
        },
        {
            name: 'Einmalig',
            selector: 'participation_once_total',
            sortable: true,
            maxWidth: '150px',
            omit: false,
            cell: (row: any) => (
                <DoubleRowCell
                    firstRow={row.participation_once_total}
                    type={"currency"}
                    secondRow={((row.participation_once_total !== "") && billingPeriodTranslations["participation_once_total"])}
                    redirectToEmployeeDetailPage={() => handleRowClicked(row)}
                />
            )
        },
        {
            name: 'Monatlich',
            selector: 'participation_monthly',
            sortable: true,
            maxWidth: '150px',
            omit: false,
            cell: (row: any) => (
                <DoubleRowCell
                    firstRow={row.participation_monthly}
                    type={"currency"}
                    secondRow={((row.participation_monthly !== "") && billingPeriodTranslations["monthly"])}
                    redirectToEmployeeDetailPage={() => handleRowClicked(row)}
                />
            )
        },
        {
            name: 'Standort',
            selector: 'workplace_location', // todo
            minWidth: '220px',
            sortable: true,
            omit: false,
            cell: (row: any) => (
                <DoubleRowCell
                    firstRow={row.workplace_location}
                    secondRow={''}
                    redirectToEmployeeDetailPage={() => handleRowClicked(row)}
                />
            )
        },
        {
            name: 'Ersteintritt',
            selector: 'first_entry', // todo
            sortable: true,
            omit: swapContractWithSalary,
            width: '120px',
            cell: (row: any) => (
                <DateCell
                    firstRow={row.first_entry}
                    redirectToEmployeeDetailPage={() => handleRowClicked(row)}
                />
            )
        },
        {
            name: 'Versand',
            selector: 'shipping_method',
            sortable: true,
            minWidth: '80px',
            omit: false,
            cell: (row: any) => (
                <DoubleRowCell
                    firstRow={(alternativeFilterLabels['shipping_method'][row.shipping_method] !== undefined ? alternativeFilterLabels['shipping_method'][row.shipping_method]['label'] : "")}
                />
            )
        },
        {
            name: 'Vertrag versendet',
            selector: 'contract_sent', // todo
            sortable: true,
            omit: !swapContractWithSalary,
            minWidth: '120px',
            cell: (row: any) => <DoubleRowCell firstRow={row.contract_sent}/>
        },
        {
            name: (
                <DashboardSelect
                    marginLeft={0}
                    selectedOption={''}
                    allOptions={Object.values(filterLabels.participation_state)}
                    handleOptionChange={(status: any) => {
                        handleParticipationStateChange(status, selectedPersonalNumbers.join(','), year)
                    }}
                    allowEmpty={false}
                />
            ),
            selector: 'participation_state',
            fallbackName: 'Status',
            minWidth: '270px',
            sortable: false,
            omit: false,
            cell: (row: any) => (
                <DashboardSelect
                    selectedOption={createDashboardStatusSelectOptions(row)}
                    allOptions={Object.values(filterLabels.participation_state)}
                    personalNumber={row.personal_number}
                    year={year}
                    handleOptionChange={handleParticipationStateChange}
                />
            )
        },
    ];

    function convertArrayOfObjectsToCSV(array: UserDataRow[], headers: CSVHeaders) {
        let result: any;

        const columnDelimiter = '","';
        const lineDelimiter = '"\n';
        const keys = Object.keys(headers);

        const headerList = Object.keys(headers);
        const headerLabels = Object.values(headers);
        if (headerList.indexOf('name') > 0) {
            headerList.splice(headerList.indexOf('name'), 1);
            headerLabels.splice(headerLabels.indexOf('Name'), 1);
        }

        result = '"';
        result += headerLabels.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item: any) => {
            let ctr = 0;
            result += '"';
            keys.forEach((key) => {
                if (headerList.includes(key)) {
                    if (ctr > 0) {
                        result += columnDelimiter;
                    }
                    result += item[key];
                    ctr++;
                }
            });
            result += lineDelimiter;
        });

        return result;
    }

    const filterElements = (
        {
            useFilter = filter,
            data = datatable,
            labels = filterLabels as any
        }
    ) => {
        dispatch(setParticipationFilter(redirectTo, useFilter));

        const tmpAllFilter: any = {};
        Object.keys(useFilter).forEach((filterId: string) => {
            let tmpFilter = useFilter[filterId];
            if (tmpFilter === null || tmpFilter === undefined) {
                tmpFilter = '';
            }

            if (tmpFilter !== '' && filterId !== 'text') {
                tmpFilter = labels[filterId][tmpFilter]['label']
            }

            tmpAllFilter[filterId] = tmpFilter;
        });

        if (data.length > 0) {
            let updatedDatatable = data.filter((item: any) => {
                let dataContainsFilter = false;
                Object.keys(item).map((key) => {
                    if (item[key] !== null) {
                        let tmpValue = item[key];
                        if (typeof item[key] === "number") {
                            tmpValue = item[key].toString();
                        }

                        try {
                            if (tmpValue.toLowerCase().includes(tmpAllFilter.text.toLowerCase())) {
                                dataContainsFilter = true;
                            }
                        } catch (err) {
                        }
                    }
                });

                return dataContainsFilter;
            });

            Object.keys(tmpAllFilter).forEach((id) => {
                updatedDatatable = filterItemsById(id, tmpAllFilter[id], updatedDatatable);
            });
            setFilteredDatatable(updatedDatatable);
        }
    };

    const filterItemsById = (filterId: string, filterValue: any, data: any) => {
        return data.filter((item: any) => {
            let filter = filterValue.toLowerCase();
            if (filterId === 'shipping_method') {
                filter = filter.replace('-', '');
            }

            return !item.hasOwnProperty(filterId) || filterValue === null || item[filterId].toLowerCase().includes(filter)
        })
    };

    const paginationSettings = {
        rowsPerPageText: 'Zeilen pro Seite:'
    };

    const sortDatatable = (rows: any, selector: any, direction: any) => {
        if (rows.length < 1 || selector === null) {
            return rows;
        }

        const updatedRows = rows.sort((rowA: any, rowB: any) => {
            const aFirstEntry = rowA[selector];
            const bFirstEntry = rowB[selector];

            if (aFirstEntry === null
                || bFirstEntry === null
                || aFirstEntry === ""
                || bFirstEntry === ""
            ) {
                let order = -1;
                if (aFirstEntry !== null && aFirstEntry !== "") {
                    order = 1;
                }
                return direction === 'desc' ? order * -1 : order;
            }

            let order = 0;
            if (selector !== 'first_entry'
                && typeof aFirstEntry === "string"
                && typeof bFirstEntry === "string"
            ) {
                if (aFirstEntry.localeCompare(bFirstEntry) > 0) {
                    order = 1;
                } else {
                    order = -1;
                }
                return direction === 'desc' ? order * -1 : order;
            }

            if (selector !== 'first_entry'
                && typeof aFirstEntry === "number"
                && typeof bFirstEntry === "number"
            ) {
                if (aFirstEntry > bFirstEntry) {
                    order = 1;
                } else {
                    order = -1;
                }
                return direction === 'desc' ? order * -1 : order;
            }

            const bIsAfterA = moment(aFirstEntry, 'DD.MM.YYYY').isAfter(moment(bFirstEntry, 'DD.MM.YYYY'));
            const sortOrder = bIsAfterA ? 1 : -1;
            return direction === 'desc' ? sortOrder * -1 : sortOrder;
        });

        return [...updatedRows];
    };

    const onDownloadAction = async (key: string) => {
        const requestBody = new FormData();
        requestBody.append('personal_numbers', selectedPersonalNumbers.join(','));

        if (year !== null) {
            requestBody.append('year', year);
        }

        switch (key) {
            case 'anschreiben':
                requestBody.append('anschreiben_type', 'digital');
                break;
            case 'anschreiben_print':
                requestBody.append('anschreiben_type', 'digital');
                break;
            case 'contract':
                requestBody.append('anschreiben_type', 'contract');
                break;
            case 'contract_doubleprint':
                requestBody.append('anschreiben_type', 'contract_doubleprint');
                break;
            default:
                return;
        }

        await getAnschreibenPdf(requestBody, '/management/generate-anschreiben');
        getData();
    };

    const onExportAction = async (key: string) => {
        if (key === 'employee') {
            downloadAsCSV(currentlySelectedRows, 'MitarbeiterInnen_Export.csv', CsvParticipationMapping);
        } else if (key === 'registrations') {
            const data = await getRegistrationFormExport();
            downloadAsCSV(data, 'Anmeldungen_Export.csv', CsvAnmeldungMapping);
        }
    };

    const downloadOptions: any = {
        'anschreiben': {
            'title': 'Angebot',
            'disabled': (selectedPersonalNumbers.length === 0)
        },
        'contract': {
            'title': 'Vertrag',
            'disabled': (selectedPersonalNumbers.length === 0)
        },
        'contract_doubleprint': {
            'title': 'Vertrag (Doppeldruck)',
            'disabled': (selectedPersonalNumbers.length === 0)
        }
    };

    if (user !== null && user.hasOwnProperty('sub') && user.sub.toLowerCase() !== 'nicolas.brunken@matthaei.de' && user.sub.toLowerCase() !== 'jessica.bengsch@matthaei.de') {
        downloadOptions['anschreiben_print'] = {
            'title': 'Angebot Druck',
            'disabled': (selectedPersonalNumbers.length === 0)
        };
    }

    let DashboardFilterWrapper = <div style={{width: '100%'}}>
        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', padding: '25px 10px'}}>
            <div className={'dropdown-filter'} style={{display: 'flex', flexWrap: 'wrap'}}>
                {Object.keys(filterLabels).map((filterId: string) => {
                    return <DashboardSelect
                        key={filterId}
                        marginBottom={'5px'}
                        marginTop={'5px'}
                        selectedOption={filter[filterId]}
                        allOptions={Object.values(filterLabels[filterId])}
                        handleOptionChange={(statusOption: any) => {
                            setFilter({...filter, [filterId]: statusOption.value});
                            filterElements({useFilter: {...filter, [filterId]: statusOption.value}});
                        }}
                        allowEmpty={true}
                    />
                })}
            </div>

            <DashboardFilter
                onFilter={(e: any) => {
                    const text = e.target.value;
                    setFilter({...filter, text});
                }}
                filterText={filter.text}
            />
        </div>
    </div>;

    if (useDashboardHeader) {
        DashboardFilterWrapper = <div style={{width: '100%'}}>
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', padding: '15px 0'}}>
                <div>
                    <DashboardFilter
                        onFilter={(e: any) => {
                            const text = e.target.value;
                            setFilter({...filter, text});
                        }}
                        filterText={filter.text}
                    />
                </div>

                <DashboardNotifyFilter
                    onChangeFilter={(filter: string) => {
                        setDashboardReplyDurationFilter(filter);
                        updateDashboardFilter(filter);
                        getData(year, filter);
                    }}
                />
            </div>
        </div>;
    }

    const DashboardPaginationWrapper = (
        {
            rowsPerPage,
            rowCount,
            currentPage,
            direction,
            paginationRowsPerPageOptions,
            paginationIconLastPage,
            paginationIconFirstPage,
            paginationIconNext,
            paginationIconPrevious,
            paginationComponentOptions,
            onChangeRowsPerPage,
            onChangePage
        }: any
    ) => {
        return <DashboardPagination
            rowsPerPage={rowsPerPage}
            rowCount={rowCount}
            currentPage={currentPage}
            direction={direction}
            paginationRowsPerPageOptions={paginationRowsPerPageOptions}
            paginationIconLastPage={paginationIconLastPage}
            paginationIconFirstPage={paginationIconFirstPage}
            paginationIconNext={paginationIconNext}
            paginationIconPrevious={paginationIconPrevious}
            paginationComponentOptions={paginationComponentOptions}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            downloadOptions={downloadOptions}
            onDownloadAction={onDownloadAction}
            selectedPersonalNumbers={selectedPersonalNumbers}
            onExportAction={onExportAction}
        />
    };

    return (
        <div className={"list-wrapper " + (useDashboardHeader ? '' : '_no-padding')}>
            {showDatatable && (
                <>
                    <div className="list-table table-responsive">
                        <div className={"datatable-loader"} style={{display: (updatingDatatable ? 'flex' : 'none')}}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        <DataTable
                            // @ts-ignore
                            columns={columns}
                            data={filteredDatatable}
                            selectableRows={true}
                            highlightOnHover={true}
                            pagination={true}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            clearSelectedRows={clearSelectedRows}
                            paginationComponentOptions={paginationSettings}
                            noHeader
                            subHeader
                            subHeaderComponent={DashboardFilterWrapper}
                            paginationComponent={DashboardPaginationWrapper}
                            sortFunction={sortDatatable}
                            selectableRowsComponent={Checkbox}
                            onRowClicked={(row) => handleRowClicked(row)}
                            onSelectedRowsChange={onSelectedRowChange}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
