import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { requestApiEndpoint } from "../services/ApiService";
import { useDispatch, useSelector } from 'react-redux';
import { setNavigation } from '../redux/actions';

export default function SideNavigation(): JSX.Element {
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const menu: any = useSelector((state: any) => state.app.navigation);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        return requestApiEndpoint(null, '/management/share-dashboard')
            .then((response: any) => {
                dispatch(setNavigation(response.menu));
            }).catch(err => {
                console.error(err);
            });
    };

    return (
        <div className={"sidebar"}>
            <div className={"sidebar-list"}>
                <Link
                    to={{
                        pathname: '/dashboard',
                        state: {
                            clearFilter: true
                        }
                    }}
                    className={"__sidebar-list-item" + (pathname.includes('dashboard') ? ' active' : ' inactive')}
                >
                    <p className={"sidebar-list-header"}>
                        Dashboard
                    </p>
                </Link>
                <Link to={{
                    pathname: '/participations',
                    state: {
                        clearFilter: true
                    }
                }}
                      className={"__sidebar-list-item" + (pathname.includes('participations') ? ' active' : ' inactive')}>
                    <p className={"sidebar-list-header"}>
                        Beteiligungen
                    </p>
                </Link>
                {menu !== undefined && menu !== null && Object.keys(menu.notifications).sort().reverse().map((item: any) => {
                    return <Link
                        key={item}
                        to={{
                            pathname: `/participations/${item}`,
                            state: {
                                clearFilter: true
                            }
                        }}
                        className={"__sidebar-list-item"}
                    >
                        <p className={"sidebar-list-entry _with-number" + (pathname.includes(`participations/${item}`) ? ' active' : '')}>
                            {item}
                            {parseInt(menu.notifications[item]) > 0 && (
                                <Link
                                    to={{
                                        pathname: `/participations/${item}`,
                                        state: {
                                            filter: {
                                                status: "Rückantwort – Interesse"
                                            }
                                        }
                                    }}
                                    className={"__participation-number"}
                                >
                                    {menu.notifications[item]}
                                </Link>
                            )}
                        </p>
                    </Link>
                })}
            </div>
        </div>
    );
}
