import {combineReducers} from 'redux'
import authReducer, {AuthenticationInterface} from "./reducer/authReducer";
import appReducer, {AppInterface} from "./reducer/appReducer";

export interface State {
    auth: AuthenticationInterface,
    app: AppInterface
}

export default combineReducers<State>({
    'auth': authReducer,
    'app': appReducer
})
