import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux';
import { postLogin, requestApiEndpoint } from '../services/ApiService';
import history from './history';
import jwt_decode from "jwt-decode";

export interface UserLogin {
    type: 'LOGIN_FETCHING'
    isFetching: boolean
}

export interface SetUserCredentials {
    type: 'USER_CREDENTIALS_SET',
    access_token: string | null,
    refresh_token: string | null,
    user: any | null
}

export const fetchUserLogin = (isFetching: boolean): UserLogin => {
    return {
        type: 'LOGIN_FETCHING',
        isFetching
    }
};

export const setNavigation = (menu: any) => {
    return {
        type: 'SET_NAVIGATION',
        menu: menu
    }
};

export const setUserCredentials = (access_token: string | null, refresh_token: string | null, user: any | null): SetUserCredentials => {
    return {
        type: 'USER_CREDENTIALS_SET',
        access_token,
        refresh_token,
        user
    }
};

export const loginError = (error: string | null) => {
    return {
        type: 'LOGIN_ERROR',
        error
    }
};

export const setParticipationFilter = (page: string, filters: any) => {
    return {
        type: 'SET_PARTICIPATION_FILTER',
        page,
        filters
    }
};

export const updateStatusForUser = (status: string, personalNumber: string, year: number | null): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    const requestBody = new FormData();
    requestBody.append('personal_numbers', personalNumber);
    requestBody.append('participation_status', status);

    if (year !== null) {
        requestBody.append('year', year.toString());
    }

    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        await requestApiEndpoint(requestBody, '/management/employee/set-participation')
            .then(data => {
                console.log('Status upgedated.')
            })
            .catch(err => {
                console.log('Status konnte nicht upgedated werden.')
            })
    }
};

export const loginUser = (email: string, password: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    const requestBody = new FormData();
    requestBody.append('username', email);
    requestBody.append('password', password);

    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        dispatch(loginError(null));

        await postLogin(email, password)
            .then(data => {
                dispatch(setUserCredentials(data.access_token, data.refresh_token, jwt_decode(data.access_token)));
                history.push('/dashboard');
            })
            .catch(err => {
                dispatch(loginError('Invalid login.'));
            })
    }
};

export const logoutUser = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        // todo: implement revoke access token endpoint
        dispatch(setUserCredentials(null, null, null));
        history.push('/login');
    }
};
