import { ReactComponent as Chevron } from '../../styles/icons/chevron-down.svg';

export default function DropDown({icon = <div/>, title, options, onAction}: any) {
    const actionWrapper = (item: string) => {
        if (!options[item]['disabled']) {
            onAction(item);
        }
    };

    return <div className="dropdown ml-2">
        <button
            className="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenuButtonTwo"
            data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false"
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            {icon}
            &nbsp;
            {title}
            <Chevron style={{height: '1em', width: '1em'}} className={"ml-2"}/>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButtonTwo">
            {Object.keys(options).map((item: any, index: number) => {
                return <div
                    key={index}
                    className="dropdown-item"
                    style={(options[item]['disabled'] ? {opacity: 0.5, cursor: 'not-allowed'} : {cursor: 'pointer'})}
                    onClick={() => actionWrapper(item)}
                >
                    {options[item]['title']}
                </div>
            })}
        </div>
    </div>
}
