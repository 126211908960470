import React from "react";
import ToOverviewButton from "./ToOverviewButton";

interface HeaderComponent {
    title: string,
    preTitle?: string | string[] | null,
    suffix?: string,
    NavigationComponent?: any,
    noPadding?: boolean
}

export default function Header(
    {
        title,
        preTitle,
        suffix = '',
        NavigationComponent = <ToOverviewButton/>,
        noPadding = false
    }: HeaderComponent): JSX.Element {
    return (
        <div className="header _content">
            <div className={"header-body " + (noPadding ? "_no-padding" : "")}>
                <div className="header-pretitle">
                    {preTitle}
                </div>
                <div className="header-title-wrapper">
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end'
                    }}>
                        <h1 className="header-title">
                            {title}
                        </h1>
                        {suffix !== '' && (
                            <div className="header-title-suffix">
                                ({suffix})
                            </div>
                        )}
                    </div>
                    <div className="__header-pagination">
                        {NavigationComponent}
                    </div>
                </div>
            </div>
        </div>
    )
}
