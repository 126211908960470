// States' definition
export interface AppInterface {
    navigation: any,
    filter: any
}

const initialState = {
    navigation: null,
    filter: null
};

const appReducer = (state: AppInterface = initialState, action: any): AppInterface => {
    switch (action.type) {
        case 'SET_NAVIGATION':
            return {...state, navigation: action.menu};

        case 'SET_PARTICIPATION_FILTER':
            return {...state, filter: {...state.filter, [action.page]: action.filters}};

        default:
            return state
    }
};

export default appReducer;
