import React, { useEffect, useState } from 'react';
import SquareInfoCard from './Basic/SquareInfoCard';
import { requestApiEndpoint } from '../services/ApiService';
import { defaultSelectOptions } from '../redux/constants';
import CurrencyFormat from 'react-currency-format';

export default function ParticipationInfoCards() {
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [cardInfo, setCardInfo] = useState({
        'count': {},
        'volume': {}
    }) as any;

    useEffect(() => {
        (async () => {
            const data = await requestApiEndpoint(null, '/management/participation/info');
            const formattedData = formatData(data);
            setCardInfo(formattedData);
            setFinishedLoading(true);
        })();
    }, []);

    const formatData = (data: any) => {
        let formattedDashboard: any = {
            count: {
                total: data.count.total,
                billing_period: []
            },
            volume: {
                total: data.volume.total,
                billing_period: []
            }
        };
        data.count.billing_period.map((item: any) => {
            formattedDashboard.count.billing_period.push({
                title: defaultSelectOptions[item.billing_period].label,
                value: item.total_participations
            })
        });
        data.volume.billing_period.map((item: any) => {
            formattedDashboard.volume.billing_period.push({
                title: defaultSelectOptions[item.billing_period].label,
                value: <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={item.total_volume}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    suffix={'€'}
                />
            })
        });

        return formattedDashboard;
    };

    return <div style={{
        display: 'flex',
        marginTop: '40px'
    }}>
        <SquareInfoCard
            hasMargin={true}
            backgroundColor={'#FA350F'}
            smallTitle={"aktive beteiligungen"}
            largeTitle={finishedLoading ? cardInfo.count.total : "Lädt..."}
            infos={finishedLoading ? cardInfo.count.billing_period : ""}
        />
        <SquareInfoCard
            smallTitle={"beteiligungsvolumen"}
            backgroundColor={'#FF7600'}
            largeTitle={finishedLoading
                ? <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={cardInfo.volume.total}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    suffix={'€'}
                />
                : "Lädt..."}
            infos={finishedLoading ? cardInfo.volume.billing_period : ""}
        />
    </div>
}
