import "./styles/globals.scss";
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import PropTypes from 'prop-types';
import SideNavigation from "./components/SideNavigation";
import Navigation from "./components/Navigation";
import { persistor, store } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { Route, Router, Switch } from 'react-router-dom';
import Index from "./pages/index";
import Participations from './pages/participations';
import Dashboard from './pages/dashboard';
import Mitarbeiter from './pages/mitarbeiter/[id]';
import Login from './pages/login';
import history from './redux/history';
import { ToastContainer } from 'react-toastify';

function App({Component, pageProps}: any) {
    const {pathname} = history.location;
    const hasSidebarPages = ["dashboard", 'participations', 'mitarbeiter'];

    let withSidebar = false;
    let pageLayout = '_default';

    hasSidebarPages.forEach((link: string) => {
        if (pathname.includes(link)) {
            withSidebar = true;
            pageLayout = '_columns';
        }
    });

    return (
        <Router history={history}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <div className={`header ${pageLayout}-navbar`}>
                        <Navigation page={pathname}/>
                    </div>
                    <div className={`__page-content ${pageLayout}`}>
                        {withSidebar &&
                        <SideNavigation/>
                        }
                        <div className={"__main-content"}>
                            <Switch>
                                <Route exact path="/login">
                                    <Login/>
                                </Route>
                                <Route exact path="/dashboard">
                                    <Dashboard/>
                                </Route>
                                <Route exact path="/participations">
                                    <Participations/>
                                </Route>
                                <Route exact path="/participations/:year">
                                    <Participations/>
                                </Route>
                                <Route exact path="/mitarbeiter/:id/:redirect?/:redirectParam?">
                                    <Mitarbeiter/>
                                </Route>
                                <Route path="/:hash" component={Index}/>
                                <Route path="/" component={Index}/>
                            </Switch>
                        </div>
                    </div>
                    <div className="footer">
                        <img src="/images/logos/wortmarke_matthaei.png" alt={"Matthäi"}/>
                    </div>
                </PersistGate>
            </Provider>
        </Router>
    )
}

App.propTypes = {
    Component: PropTypes.any,
    pageProps: PropTypes.any
};

export default App;
