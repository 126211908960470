import React from "react";
import CurrencyFormat from 'react-currency-format';

export default function DoubleRowCell(
    {
        firstRow,
        secondRow = '',
        redirectToEmployeeDetailPage = () => {},
        type = "text"
    }: any): JSX.Element {

    return (
        <div onClick={redirectToEmployeeDetailPage} style={{cursor: 'pointer'}}>
            {type === "currency"
                ? <div style={{
                    fontWeight: 400,
                    paddingBottom: '3px',
                    fontSize: '14px'
                }}>
                    <CurrencyFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        value={firstRow}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={'€'}
                    />
                </div>
                : <div style={{
                    fontWeight: 400,
                    paddingBottom: '3px',
                    fontSize: '14px'
                }}>{firstRow}</div>
            }
            <div className={'text-muted'} style={{fontSize: '13px'}}>{secondRow}&nbsp;</div>
        </div>
    )
}
