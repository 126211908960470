import React from "react";
import { ReactComponent as IconNext } from '../../styles/icons/chevron-right.svg';
import { ReactComponent as IconPrevious } from '../../styles/icons/chevron-left.svg';
import { ReactComponent as IconFirst } from '../../styles/icons/chevron-double-left.svg';
import { ReactComponent as IconLast } from '../../styles/icons/chevron-double-right.svg';
import { ReactComponent as DownloadIcon } from '../../styles/icons/download.svg';
import { ReactComponent as ExportIcon } from '../../styles/icons/export.svg';
import DropDown from "./DropDown";


interface PaginationProps {
    rowsPerPage: number;
    rowCount: number;
    currentPage: number;
    direction?: string;
    paginationRowsPerPageOptions?: number[];
    paginationIconLastPage?: React.ReactNode;
    paginationIconFirstPage?: React.ReactNode;
    paginationIconNext?: React.ReactNode;
    paginationIconPrevious?: React.ReactNode;
    paginationComponentOptions?: any;
    onChangePage: (page: number) => void;
    onChangeRowsPerPage: (numRows: number, currentPage: number) => void;
    downloadOptions: any,
    onDownloadAction: any,
    selectedPersonalNumbers: string[],
    onExportAction: any,
}

export default function DashboardPagination(
    {
        rowsPerPage,
        rowCount,
        currentPage,
        paginationRowsPerPageOptions = [10, 15, 20, 25, 30],
        onChangeRowsPerPage,
        onChangePage,
        downloadOptions,
        onDownloadAction,
        selectedPersonalNumbers = [],
        onExportAction,
    }: PaginationProps
) {
    const handleRowsPerPage = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => onChangeRowsPerPage(Number(e.target.value), currentPage),
        [currentPage, onChangeRowsPerPage],
    );

    const selectOptions = paginationRowsPerPageOptions.map((num: number) => (
        <option key={num} value={num}>
            {num}
        </option>
    ));

    const getNumberOfPages = (rowCount: number, rowsPerPage: number) => {
        return Math.ceil(rowCount / rowsPerPage);
    };

    const handlePrevious = React.useCallback(() => {
            if (currentPage > 1) {
                onChangePage(currentPage - 1)
            }
        },
        [currentPage, onChangePage]
    );

    const handleNext = React.useCallback(() => onChangePage(currentPage + 1), [currentPage, onChangePage]);
    const handleFirst = React.useCallback(() => onChangePage(1), [onChangePage]);
    const handleLast = React.useCallback(
        () => onChangePage(getNumberOfPages(rowCount, rowsPerPage)),
        [onChangePage, rowCount, rowsPerPage],
    );

    const options = {
        selectAllRowsItem: false,
        selectAllRowsItemText: "Alle auswählen",
        rowsPerPageText: "von"
    };

    if (options.selectAllRowsItem) {
        selectOptions.push(
            <option key={-1} value={rowCount}>
                {options.selectAllRowsItemText}
            </option>,
        );
    }

    const select = (
        <select
            style={{
                color: '#909090',
                background: 'transparent',
                border: 0,
                width: '40px'
            }}
            onChange={handleRowsPerPage}
            defaultValue={rowsPerPage}
            aria-label={options.rowsPerPageText}
        >
            {selectOptions}
        </select>
    );

    const iconDimensions = {
        width: 20,
        height: 20
    };

    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === getNumberOfPages(rowCount, rowsPerPage);

    return <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '25px 30px 25px 30px',
        background: '#F7F7F7',
        color: '#909090',
        alignItems: 'center'
    }}>
        <div>pro Seite {select} von {rowCount}</div>
        <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '20px',
            color: '#000'
        }}>
            <div
                className={"ml-1 mr-1"}
                style={{cursor: (isFirstPage ? 'not-allowed' : 'pointer'), color: (isFirstPage ? '#909090' : '#000')}}
                onClick={handleFirst}
            >
                <IconFirst
                    width={iconDimensions["width"]}
                    height={iconDimensions["height"]}
                />
            </div>
            <div
                className={"ml-1 mr-1"}
                style={{cursor: (isFirstPage ? 'not-allowed' : 'pointer'), color: (isFirstPage ? '#909090' : '#000')}}
                onClick={handlePrevious}
            >
                <IconPrevious
                    width={iconDimensions["width"]}
                    height={iconDimensions["height"]}
                />
            </div>
            <div className={"ml-1 mr-1"}
                 style={{paddingTop: '3px'}}>{currentPage} von {Math.ceil(rowCount / rowsPerPage)}</div>
            <div
                className={"ml-1 mr-1"}
                style={{cursor: (isLastPage ? 'not-allowed' : 'pointer'), color: (isLastPage ? '#909090' : '#000')}}
                onClick={handleNext}
            >
                <IconNext
                    width={iconDimensions["width"]}
                    height={iconDimensions["height"]}
                />
            </div>
            <div
                className={"ml-1 mr-1"}
                style={{cursor: (isLastPage ? 'not-allowed' : 'pointer'), color: (isLastPage ? '#909090' : '#000')}}
                onClick={handleLast}
            >
                <IconLast
                    width={iconDimensions["width"]}
                    height={iconDimensions["height"]}
                />
            </div>
        </div>
        <div style={{
            display: 'flex'
        }}>
            <DropDown
                className={"mr-2"}
                icon={<DownloadIcon/>}
                title={"Download"}
                options={downloadOptions}
                onAction={(item: string) => onDownloadAction(item)}
            />

            <DropDown
                className={"mr-2"}
                icon={<ExportIcon/>}
                title={"Export"}
                options={{
                    'employee': {
                        'title': 'Mitarbeiterliste',
                        'disabled': (selectedPersonalNumbers.length === 0)
                    },
                    'registrations': {
                        'title': 'Anmeldungen',
                        'disabled': false
                    }
                }}
                onAction={(item: string) => onExportAction(item)}
            />
        </div>
    </div>;
}
