import React, { useState } from "react";
import Header from "../components/Basic/Header";
import ParticipationDashboard from '../components/ParticipationDashboard';
import ParticipationInfoCards from '../components/ParticipationInfoCards';
import { withRouter } from "react-router-dom";

function Dashboard({location}: any): JSX.Element {
    const [dashboardIsVisible, setDashboardIsVisible] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState('seit 1 Woche');

    let clearFilter = false;
    if (location.state !== undefined && location.state.clearFilter !== undefined) {
        clearFilter = location.state.clearFilter;
    }

    const setDashboardVisibilityWrapper = (isVisible: boolean) => {
        setDashboardIsVisible(isVisible);
    };

    const setDashboardFilter = (filter: string) => {
        const filterText: any = {
            '1-week': '1 Woche',
            '2-weeks': '2 Wochen',
            '3-weeks': '3 Wochen',
            '4-weeks': '4 Wochen'
        };

        setSelectedFilter(`seit ${filterText[filter]}`);
    };

    return (
        <div className={"container"}>
            <ParticipationInfoCards/>
            {dashboardIsVisible && (
                <Header
                    title={"Vertrag ausstehend"}
                    suffix={selectedFilter}
                />
            )}
            <ParticipationDashboard
                updateDashboardFilter={setDashboardFilter}
                swapContractWithSalary={true}
                showDatatable={dashboardIsVisible}
                onDashboardVisibilityChange={(isVisible: boolean) => setDashboardVisibilityWrapper(isVisible)}
                clearFilter={clearFilter}
                filterNotReplied={true}
                useDashboardHeader={true}
            />
        </div>
    );
}

export default withRouter(Dashboard);
