import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Header from "../../components/Basic/Header";
import { requestApiEndpoint } from "../../services/ApiService";
import moment from "moment";
import { BillingPeriodSelect } from "../../components/Form/BillingPeriodSelect";
import ToOverviewButton from "../../components/Basic/ToOverviewButton";
import CurrencyFormat from "react-currency-format";
import { ShippingMethodSelect } from "../../components/Form/ShippingMethodSelect";
import { alternativeFilterLabels } from "../../redux/constants";

export default function Mitarbeiter(): JSX.Element {
    // @ts-ignore
    const {id = 0, redirect = "", redirectParam = ""} = useParams();
    const [missingParticipationTypes, setMissingParticipationTypes] = useState([]);
    const [participations, setParticipations] = useState<any>([]);
    const [editUser, setEditUser] = useState(false);
    const [tempUserData, setTempUserData] = useState<any>({});
    const [userErrors, setUserErrors] = useState<string[]>([]);
    const [participationErrors, setParticipationErrors] = useState({}) as any;
    const [userData, setUserData] = useState<any>({});
    const [editParticipation, setEditParticipation] = useState({});
    const [allBillingTypes, setAllBillingTypes] = useState([]);
    const [headerRedirect, setHeaderRedirect] = useState('/dashboard');

    useEffect(() => {
        if (id) {
            (async () => {
                getEmployeeData(id);
            })();
        }
    }, [id]);

    useEffect(() => {
        if (redirect || redirectParam) {
            setHeaderRedirect(`/${redirect}/${redirectParam}`);
        }
    }, [redirect, redirectParam]);

    async function getEmployeeData(id: any) {
        const requestBody = new FormData();
        requestBody.append('personal_number', id);

        if (redirectParam !== null && !isNaN(parseInt(redirectParam))) {
            requestBody.append('year', redirectParam);
        }

        requestApiEndpoint(requestBody, '/management/participation/get')
            .then((data) => {
                setUserData(data.user);
                setTempUserData(data.user);
                if (data.user && data.user.participation) {
                    setAllBillingTypes(data.types);
                    const filledParticipations = fillEmptyParticipations(data.types, data.user.participation);
                    const updatedEditingStatus = createEditingStatus(filledParticipations);
                    setParticipations(filledParticipations);
                    setEditParticipation(updatedEditingStatus);
                }
            })
            .catch((err) => {
                console.error(err)
            });
    }

    const deleteParticipationFromState = (id: string) => {
        let tmpParticipations = {...participations};
        delete tmpParticipations[id];
        setParticipations(tmpParticipations);
        calculateMissingTypes(allBillingTypes, tmpParticipations);
    };

    const deleteParticipationError = (id: string) => {
        let tmpParticipationErrors = {...participationErrors};
        delete tmpParticipationErrors[id];
        setParticipationErrors(tmpParticipationErrors);
    };

    const deleteEmployeeParticipation = (participation: any) => {
        // if participation id === new nur state clearen ansonsten api call
        if (participation.id === "new") {
            deleteParticipationFromState("new");
            deleteParticipationError("new");
            return;
        }

        const requestBody = new FormData();
        requestBody.append('participation_id', participation.id);
        requestBody.append('deposit', "0");
        requestBody.append('billingPeriod', "monthly_salary");
        requestBody.append('transactionDatetime', "01.01.2020");

        requestApiEndpoint(requestBody, '/management/participation/update')
            .then(() => {
                deleteParticipationFromState(participation.id);
            })
            .catch((err) => {
                console.error(err);
            })
    };

    const addParticipation = (disabled = false, missingParticipationTypes: any) => {
        if (!disabled && (Object.keys(missingParticipationTypes).length > 0)) {
            setParticipations({
                ...participations,
                "new": {
                    id: "new",
                    billing_period: missingParticipationTypes[0].value
                }
            });

            setEditParticipation({
                ...editParticipation,
                "new": true,
            });
        }
    };

    const updateUser = () => {
        const saveUser = editUser;
        if (saveUser) {
            const requestBody = new FormData();
            requestBody.append('id', tempUserData.id);
            requestBody.append('firstName', tempUserData.first_name);
            requestBody.append('lastName', tempUserData.last_name);
            requestBody.append('personalNumber', tempUserData.personal_number);
            requestBody.append('email', tempUserData.email);
            requestBody.append('streetAndHouseNo', tempUserData.street_and_house_no);
            requestBody.append('zip', tempUserData.zip);
            requestBody.append('place', tempUserData.place);
            requestBody.append('salaryKind', tempUserData.salary_kind);
            requestBody.append('workplaceLocation', tempUserData.workplace_location);
            requestBody.append('shippingMethod', tempUserData.shipping_method);

            if (redirectParam !== null && !isNaN(parseInt(redirectParam))) {
                requestBody.append('year', redirectParam);
            }

            requestApiEndpoint(requestBody, '/management/employee/edit-user')
                .then((data) => {
                    if (data.user) {
                        setUserData(data.user);
                        setTempUserData(data.user);
                        setEditUser(!editUser);
                        setUserErrors([]);
                    }
                })
                .catch((err) => {
                    if (err.error) {
                        const errorFields: any = Object.keys(err.error);
                        setUserErrors(errorFields);
                    }
                });
        } else {
            setEditUser(!editUser);
        }
    };

    const createEditingStatus = (participations: any) => {
        let status: any = {};
        Object.keys(participations).forEach(function (partId: any) {
            status[partId] = false;
        });

        return status;
    };

    const calculateMissingTypes = (types = allBillingTypes, tmpParticipations = participations) => {
        let existingTypes: any = [];
        let allTypes = types;
        Object.values(tmpParticipations).forEach((participation: any) => {
            if (participation.id === "new") {
                return;
            }

            existingTypes.push(participation.billing_period);
        });

        const missingTypes = allTypes.filter(function (type: any) {
            return !existingTypes.includes(type);
        });

        let fullMissingTypes: any = [];
        missingTypes.forEach((type: string) => {
            fullMissingTypes.push({value: type, label: billingPeriodOptions[type]});
        });

        setMissingParticipationTypes(fullMissingTypes);
    };

    const fillEmptyParticipations = (types: any = [], tmpParticipations: any) => {
        calculateMissingTypes(types, tmpParticipations);

        let updatedParticipations: any = {};
        tmpParticipations.forEach((part: any) => {
            updatedParticipations[part.id] = part;
        });

        return updatedParticipations;
    };

    const billingPeriodOptions: any = {
        'once_salary': 'Einmalzahlung Gehalt/Lohn',
        'once_direct': 'Einmalzahlung Direkt',
        'monthly_salary': 'Monatlich Gehalt/Lohn'
    };

    const billingPeriodMinPrices: any = {
        'once_salary': 240,
        'once_direct': 1000,
        'monthly_salary': 20
    };

    async function addEmployeeParticipation(participation: any, userData: any) {
        // @ts-ignore
        const saveParticipation = editParticipation[participation.id];

        if (saveParticipation) {
            if (participation.deposit === "0"
            ) {
                deleteParticipationError(participation.id);
                deleteParticipationFromState(participation.id);
                return;
            }

            let formattedDeposit;
            try {
                formattedDeposit = participation.deposit.toString();
            } catch (err) {
                formattedDeposit = "";
            }

            if (formattedDeposit.includes(",")) {
                formattedDeposit = formattedDeposit.replace(',', '.');
            }

            const requestBody = new FormData();
            requestBody.append('deposit', formattedDeposit);
            requestBody.append('billingPeriod', participation.billing_period);

            if (redirectParam !== null && !isNaN(parseInt(redirectParam))) {
                requestBody.append('year', redirectParam);
            }

            if (participation.transaction_datetime !== undefined) {
                requestBody.append('transactionDatetime', participation.transaction_datetime);
            }

            if (("id" in participation) && participation["id"] !== "new") {
                // @ts-ignore
                requestBody.append('participation_id', parseInt(participation.id));
                requestApiEndpoint(requestBody, '/management/participation/update')
                    .then((data) => {
                        let tmpParticipations = {...participations};
                        if (data.deleted) {
                            delete tmpParticipations[data.deleted];
                        } else {
                            tmpParticipations = {
                                ...tmpParticipations,
                                [data.id]: {
                                    id: data.id,
                                    deposit: participation.deposit,
                                    transaction_datetime: participation.transaction_datetime,
                                    billing_period: participation.billing_period,
                                }
                            };
                        }

                        setParticipations(tmpParticipations);
                        let tmpEditParticipations: any = {...editParticipation};
                        delete tmpEditParticipations["new"];
                        setEditParticipation({
                            ...tmpEditParticipations,
                            [data.id]: false
                        });

                        deleteParticipationError(participation.id);
                        setEditParticipation({...editParticipation, [participation.id]: !saveParticipation});
                        calculateMissingTypes(allBillingTypes, tmpParticipations);
                    })
                    .catch((err) => {
                        if (err.error) {
                            const tmpParticipationErrors = {
                                ...participationErrors,
                                [participation.id]: err.error
                            };
                            setParticipationErrors(tmpParticipationErrors);
                        }
                    });
            } else {
                // @ts-ignore
                requestBody.append('personal_number', userData.personal_number);
                requestApiEndpoint(requestBody, '/management/participation/create')
                    .then((data) => {
                        let tmpParticipations = {...participations};
                        delete tmpParticipations["new"];
                        const updatedParticipations = {
                            ...tmpParticipations,
                            [data.id]: {
                                id: data.id,
                                deposit: participation.deposit,
                                transaction_datetime: participation.transaction_datetime,
                                billing_period: participation.billing_period,
                            }
                        };

                        setParticipations(updatedParticipations);
                        let tmpEditParticipations: any = {...editParticipation};
                        delete tmpEditParticipations["new"];
                        setEditParticipation({
                            ...tmpEditParticipations,
                            [data.id]: false
                        });
                        deleteParticipationError(participation.id);
                        setEditParticipation({...editParticipation, [participation.id]: !saveParticipation});
                        calculateMissingTypes(allBillingTypes, updatedParticipations);
                    })
                    .catch((err) => {
                        if (err.error) {
                            const tmpParticipationErrors = {
                                ...participationErrors,
                                [participation.id]: err.error
                            };
                            setParticipationErrors(tmpParticipationErrors);
                        }
                    });
            }
        } else {
            setEditParticipation({...editParticipation, [participation.id]: !saveParticipation});
        }
    }

    const updateUserData = (e: any) => {
        const {name, value} = e.target;
        setTempUserData({
            ...tempUserData,
            [name]: value
        });
    };

    const cancelEditUser = () => {
        setEditUser(false);
        setUserErrors([]);
        setTempUserData(userData);
    };

    const updateParticipation = (id: number, key: string, value: string) => {
        let tmpValue = value;
        if (key === "transaction_datetime") {
            tmpValue = moment(value, "YYYY-MM-DD").format("DD.MM.YYYY");
        }

        let tmpParticipations = participations;
        const updatedParticipations = {...tmpParticipations, [id]: {...tmpParticipations[id], [key]: tmpValue}};
        setParticipations(updatedParticipations);
        calculateMissingTypes(allBillingTypes, updatedParticipations);
    };

    let addParticipationStyles = {};
    if (Object.keys(participations).length > 0) {
        addParticipationStyles = {
            borderTop: 0
        }
    }

    let creatingParticipation = false;
    Object.values(participations).forEach((part: any) => {
        if (part.id === "new") {
            creatingParticipation = true;
        }
    });

    if (creatingParticipation) {
        addParticipationStyles = {
            borderTop: 0,
            opacity: 0.3,
            cursor: 'not-allowed'
        }
    }

    const handleShippingMethodChange = (method: string) => {
        const data = {
            target: {
                name: 'shipping_method',
                value: method
            }
        };
        updateUserData(data);
    };

    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Header
                        title={"Beteiligung 2021"}
                        preTitle={`${userData.first_name} ${userData.last_name}`}
                        NavigationComponent={<ToOverviewButton redirectTo={headerRedirect}/>}
                    />
                </div>
                <div className={"col-12 col-md-9 mb-5"}>
                    <div className="card card-shadow _card-border-none">
                        <div className="card-header">
                            <h4 className="card-header-title">
                                Kontaktdaten
                            </h4>
                            <div
                                className="configurator-link"
                                onClick={cancelEditUser}
                                style={{
                                    color: "#90909d",
                                    marginRight: "20px"
                                }}
                            >
                                {editUser && 'abbrechen'}
                            </div>
                            <div className="configurator-link"
                                 onClick={updateUser}>
                                {/*// @ts-ignore*/}
                                {(editUser)
                                    ? 'speichern'
                                    : 'ändern'
                                }
                            </div>
                        </div>
                        {!editUser ?
                            <div className="card-body">
                                <div className="card-text card-text-list">
                                    <div className="card-info-details">
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Name, Vorname</div>
                                            <div
                                                className={"__card-list-value"}>{userData.last_name}, {userData.first_name}</div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Personalnummer</div>
                                            <div className={"__card-list-value"}
                                            >
                                                {userData.personal_number}
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Email-Adresse</div>
                                            <div className={"__card-list-value"}
                                            >
                                                {userData.email}
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Versandmethode</div>
                                            <div className={"__card-list-value"}
                                            >
                                                {!!userData && !!userData.shipping_method && (
                                                    <>{alternativeFilterLabels['shipping_method'][userData.shipping_method]['label']}</>
                                                )}
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Lohnart</div>
                                            <div className={"__card-list-value"}
                                            >
                                                {userData.salary_kind}
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Standort</div>
                                            <div className={"__card-list-value"}
                                            >
                                                {userData.workplace_location}
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Straße, Hausnummer</div>
                                            <div className={"__card-list-value"}
                                            >
                                                {userData.street_and_house_no}
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Ort, PLZ</div>
                                            <div className={"__card-list-value"}
                                            >
                                                {userData.place}, {userData.zip}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : <div className="card-body">
                                <div className="card-text card-text-list">
                                    <div className="card-info-details">
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Name, Vorname</div>
                                            <div
                                                className={"__card-list-value"}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end'
                                                }}
                                            >
                                                <input
                                                    className={`form-control ${userErrors.includes("lastName") && "is-invalid"}`}
                                                    type={"text"}
                                                    name={"last_name"}
                                                    value={tempUserData.last_name}
                                                    onChange={updateUserData}
                                                />&nbsp;,&nbsp;<input
                                                className={`form-control ${userErrors.includes("firstName") && "is-invalid"}`}
                                                type={"text"}
                                                name={"first_name"}
                                                value={tempUserData.first_name}
                                                onChange={updateUserData}
                                            />
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Personalnummer</div>
                                            <div
                                                className={"__card-list-value"}
                                            >
                                                <input
                                                    className={`form-control ${userErrors.includes("personalNumber") && "is-invalid"}`}
                                                    type={"text"}
                                                    name={"personal_number"}
                                                    value={tempUserData.personal_number}
                                                    onChange={updateUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Email-Adresse</div>
                                            <div
                                                className={"__card-list-value"}
                                            >
                                                <input
                                                    className={`form-control ${userErrors.includes("email") && "is-invalid"}`}
                                                    type={"text"}
                                                    name={"email"}
                                                    value={tempUserData.email}
                                                    onChange={updateUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Versandmethode</div>
                                            <div
                                                className={"__card-list-value"}
                                            >
                                                <ShippingMethodSelect
                                                    selectedShipping={tempUserData.shipping_method}
                                                    handleShippingChange={handleShippingMethodChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Lohnart</div>
                                            <div
                                                className={"__card-list-value"}
                                            >
                                                <input
                                                    className={`form-control ${userErrors.includes("salaryKind") && "is-invalid"}`}
                                                    type={"text"}
                                                    name={"salary_kind"}
                                                    value={tempUserData.salary_kind}
                                                    onChange={updateUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Standort</div>
                                            <div
                                                className={"__card-list-value"}
                                            >
                                                <input
                                                    className={`form-control ${userErrors.includes("workplaceLocation") && "is-invalid"}`}
                                                    type={"text"}
                                                    name={"workplace_location"}
                                                    value={tempUserData.workplace_location}
                                                    onChange={updateUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Straße, Hausnummer</div>
                                            <div
                                                className={"__card-list-value"}
                                            >
                                                <input
                                                    className={`form-control ${userErrors.includes("streetAndHouseNo") && "is-invalid"}`}
                                                    type={"text"}
                                                    name={"street_and_house_no"}
                                                    value={tempUserData.street_and_house_no}
                                                    onChange={updateUserData}
                                                />
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Ort, PLZ</div>
                                            <div
                                                className={"__card-list-value"}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end'
                                                }}
                                            >
                                                <input
                                                    className={`form-control ${userErrors.includes("place") && "is-invalid"}`}
                                                    type={"text"}
                                                    name={"place"}
                                                    value={tempUserData.place}
                                                    onChange={updateUserData}
                                                />&nbsp;,&nbsp;
                                                <input
                                                    className={`form-control ${userErrors.includes("zip") && "is-invalid"}`}
                                                    type={"text"}
                                                    name={"zip"}
                                                    value={tempUserData.zip}
                                                    onChange={updateUserData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    {Object.values(participations).map((participation: any, index: number) => {
                        return <div
                            className="card card-shadow _card-border-none"
                            style={{
                                marginBottom: 0,
                                borderBottom: "1px solid rgba(0,0,0,.09)",
                            }}
                        >
                            {index === 0 && (
                                <div className="card-header">
                                    <h4 className="card-header-title">
                                        Beteiligung
                                    </h4>
                                </div>
                            )}
                            {/*// @ts-ignore*/}
                            {(editParticipation[participation.id]) && (
                                <div className="card-body">
                                    <div className="card-text card-text-list">
                                        <div className="card-info-details">
                                            <div className={"__card-list"}>
                                                <div className={"__card-list-name"} style={{flex: 1}}>Startdatum</div>
                                                <div
                                                    style={{
                                                        justifyContent: "space-between",
                                                        display: "flex",
                                                        flex: 2
                                                    }}
                                                >
                                                    <div className={"__card-list-value"}>
                                                        <input
                                                            type={"date"}
                                                            name={"time"}
                                                            className={"form-control " + (participationErrors[participation.id] && Object.keys(participationErrors[participation.id]).includes('transactionDatetime') ? 'is-invalid' : '')}
                                                            value={moment(participation.transaction_datetime, "DD.MM.YYYY").format('YYYY-MM-DD')}
                                                            onChange={(e) => updateParticipation(participation.id, 'transaction_datetime', e.target.value)}
                                                        />
                                                    </div>
                                                    <div className={'participation-button-group'} style={{
                                                        display: 'flex'
                                                    }}>
                                                        <div
                                                            className="configurator-link"
                                                            onClick={() => deleteEmployeeParticipation(participation)}
                                                            style={{
                                                                color: 'grey'
                                                            }}
                                                        >
                                                            löschen
                                                        </div>
                                                        <div
                                                            className="configurator-link"
                                                            onClick={() => addEmployeeParticipation(participation, userData)}
                                                            style={{
                                                                marginLeft: '20px',
                                                            }}
                                                        >
                                                            {/*// @ts-ignore*/}
                                                            {(editParticipation[participation.id])
                                                                ? 'speichern'
                                                                : 'ändern'
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"__card-list"}>
                                                <div className={"__card-list-name"}
                                                     style={{flex: 1}}>Beteiligungssumme
                                                </div>
                                                <div className={"__card-list-value"} style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    flex: 2
                                                }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <input
                                                            type={"text"}
                                                            name={"deposit"}
                                                            value={participation.deposit}
                                                            onChange={(e) => updateParticipation(participation.id, 'deposit', e.target.value)}
                                                            className={"form-control " + (participationErrors[participation.id] && Object.keys(participationErrors[participation.id]).includes('deposit') ? 'is-invalid' : '')}
                                                            style={{display: 'inline-block', flex: 1, maxWidth: '50%'}}
                                                        />&nbsp;Euro
                                                        <div style={{
                                                            fontSize: '12px',
                                                            display: 'inline-flex',
                                                            flex: 1,
                                                            marginLeft: '5px'
                                                        }}>(Der Mindestbetrag
                                                            sind: {billingPeriodMinPrices[participation.billing_period]} Euro)
                                                        </div>
                                                    </div>
                                                    {/*{participationErrors[participation.id] && Object.keys(participationErrors[participation.id]).includes('deposit') &&*/}
                                                    {/* <div className="invalid-feedback" style={{display: 'block'}}>*/}
                                                    {/*     {participationErrors[participation.id]['deposit']['isEmpty']}*/}
                                                    {/* </div>*/}
                                                    {/*}*/}
                                                </div>
                                            </div>
                                            <div className={"__card-list"}>
                                                <div className={"__card-list-name"} style={{flex: 1}}>Beteiligungsart
                                                </div>
                                                <div className={"__card-list-value"} style={{flex: 1}}>
                                                    <BillingPeriodSelect
                                                        selectOptions={missingParticipationTypes}
                                                        participation_state={participation.billing_period}
                                                        handleParticipationStateChange={(billingPeriod: any) => updateParticipation(participation.id, 'billing_period', billingPeriod)}/>
                                                </div>
                                                <div style={{flex: 1}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/*// @ts-ignore*/}
                            {!editParticipation[participation.id] && (
                                <div className="card-body">
                                    <div className="card-text card-text-list">
                                        <div className="card-info-details">
                                            <div className={"__card-list"}>
                                                <div className={"__card-list-name"}>Startdatum</div>
                                                <div
                                                    style={{
                                                        justifyContent: "space-between",
                                                        display: "flex",
                                                        flex: 1
                                                    }}
                                                >
                                                    <div className={"__card-list-value"}>
                                                        {participation.transaction_datetime}
                                                    </div>
                                                    <div className={'participation-button-group'} style={{
                                                        display: 'flex'
                                                    }}>
                                                        <div
                                                            className="configurator-link"
                                                            onClick={() => deleteEmployeeParticipation(participation)}
                                                            style={{
                                                                color: 'grey'
                                                            }}
                                                        >
                                                            löschen
                                                        </div>
                                                        <div
                                                            className="configurator-link"
                                                            onClick={() => addEmployeeParticipation(participation, userData)}
                                                            style={{
                                                                marginLeft: '20px',
                                                            }}
                                                        >
                                                            {/*// @ts-ignore*/}
                                                            {(editParticipation[participation.id])
                                                                ? 'speichern'
                                                                : 'ändern'
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"__card-list"}>
                                                <div className={"__card-list-name"}>Beteiligungssumme</div>
                                                <div className={"__card-list-value"}>
                                                    <CurrencyFormat displayType={"text"} decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    value={participation.deposit}
                                                                    thousandSeparator={'.'} decimalSeparator={','}
                                                                    suffix={'€'}/>
                                                </div>
                                            </div>
                                            <div className={"__card-list"}>
                                                <div className={"__card-list-name"}>Beteiligungsart</div>
                                                <div
                                                    className={"__card-list-value"}>{billingPeriodOptions[participation.billing_period]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    })}
                    {Object.keys(participations).length < 3 && (
                        <div
                            className={"add-participation-wrapper select-area"}
                            onClick={() => addParticipation(creatingParticipation, missingParticipationTypes)}
                            style={addParticipationStyles}
                        >
                            + Beteiligung hinzufügen
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
