import React, { useEffect, useRef, useState } from "react";

export default function DashboardNotifyFilter({onChangeFilter}: any): JSX.Element {
    const [showFilter, setShowFilter] = useState(false);
    let filterModal = useRef(null);
    let openFilterButton = useRef(null);

    const listener = (event: any) => {
        // @ts-ignore
        if (filterModal.current === null || filterModal.current.contains(event.target)
            // @ts-ignore
            || openFilterButton.current === null || openFilterButton.current.contains(event.target)
        ) {
            return;
        }
        setShowFilter(false);
    };

    useEffect(() => {
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [filterModal]);

    const onChangeFilterWrapper = (filter: string) => {
        onChangeFilter(filter);
        setTimeout(() => setShowFilter(false), 300);
    };

    return <div className={'more-filter-wrapper'}>
        <button
            ref={openFilterButton}
            type="button"
            className="btn btn-primary mb-2"
            onClick={() => setShowFilter(!showFilter)}
        >
            weitere Filter
        </button>
        <div
            className={'filter-options'}
            style={{display: (showFilter ? 'block' : 'none')}}
        >
            <div
                className="btn-group-toggle shadow-sm p-3 mb-5 bg-white rounded"
                data-toggle="buttons"
                ref={filterModal}
            >
                <label className="btn btn-white active">
                    <input
                        type="radio"
                        name="options"
                        id="option1"
                        checked={true}
                        onClick={() => onChangeFilterWrapper('1-week')}
                    />
                    1 Woche
                </label>
                <label className="btn btn-white">
                    <input
                        type="radio"
                        name="options"
                        id="option2"
                        onClick={() => onChangeFilterWrapper('2-weeks')}
                    />
                    2 Wochen
                </label>
                <label className="btn btn-white">
                    <input
                        type="radio"
                        name="options"
                        id="option2"
                        onClick={() => onChangeFilterWrapper('3-weeks')}
                    />
                    3 Wochen
                </label>
                <label className="btn btn-white">
                    <input
                        type="radio"
                        name="options"
                        id="option3"
                        onClick={() => onChangeFilterWrapper('4-weeks')}
                    />
                    4 Wochen
                </label>
            </div>
        </div>
    </div>
}
